import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { FaUserNurse } from 'react-icons/fa';
import navStyles from '../components/navigation.module.css';
import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import Logo from '../components/logo'

class Lea extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')    
    const [author] = get(this, 'props.data.allContentfulPerson.edges')   

    return (
      <Layout>
        <Helmet title={siteTitle} />
        <Logo author={author} />
        <div className="wrapper">        
        <ul className="header-list">
          <li><div className={navStyles.iconimage}><FaUserNurse  className={navStyles.icon}/></div></li>
          <h2><u>L</u>ea</h2>          
        </ul>
        <hr className="hr-repeat"></hr>
        <iframe src="https://online.fliphtml5.com/ncyyb/wzub/" id="iframe_container" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" allow="autoplay; fullscreen" height="800" width="100%"></iframe>
        
        </div>
      </Layout>
    )
  }
}

export default Lea

export const pageQuery = graphql`
  query LeaQuery {    
    allContentfulPerson(filter: { id: { eq: "e339d456-b3e8-5213-8d99-cf8ebb14a0a0" } }) {
      edges {
        node {
          name
          shortBio  {
            childMarkdownRemark {
              html
            }
          }
          headline
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
